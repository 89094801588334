import React, { useState, useEffect } from "react";
import DetailsModal from "../../components/DetailsModal";
import { IxplanAccess } from "./interface";

const XplanModal: React.FC<IxplanAccess> = (props) => {
  const [showSuccessModal, setShowSuccessModal] = useState(true);

  console.log("showModal :", props.showModal);

  useEffect(() => {
    setShowSuccessModal(props.showModal ?? false);
    console.log("showSuccessModal :", showSuccessModal);
  }, [props.showModal]);

  return (
    <>
      <DetailsModal
        showModal={showSuccessModal}
        onClose={(): void => {
          props.onClose();
        }}
        uid={props.uid ?? ""}
      />
    </>
  );
};

export default XplanModal;
