import Ajv from "ajv";
import Cookies from "js-cookie";
import clientSummaryResponseSchemaJson, {
  ClientSummarySchema,
} from "../schemas/clientSummaryResponseSchema.json";
import xplanAccessSchemaJson from "../schemas/xplanAccessSchema.json";
import xplanTransactionsSchemaJson from "../schemas/xplanTransactionsSchema.json";

const ajv = new Ajv({
  allErrors: true,
  removeAdditional: "all",
  // JSON Schema defaults could be used
  // https://json-schema.org/understanding-json-schema/reference/generic.html#annotations
  // useDefaults: true,
  schemas: [
    clientSummaryResponseSchemaJson,
    xplanAccessSchemaJson,
    xplanTransactionsSchemaJson,
  ],
});

const validateSummaryResponse = ajv.getSchema(
  "clientSummaryResponseSchema.json"
);

const validateXplanAccess = ajv.getSchema("xplanAccessSchema.json");
const validateXplanTransactions = ajv.getSchema("xplanTransactionsSchema.json");

const _fetch = (path: string, init: RequestInit = {}): any => {
  const token = Cookies.get("token");

  return fetch(process.env.REACT_APP_API_URL + path, {
    ...init,
    referrer: window.location.href,
    headers: {
      ...init.headers,
      Authorization: `Bearer ${token ? token : ""}`,
    },
  });
};

let message = "";

export const createUser = (uid: string): Promise<string> =>
  _fetch(`xplan/createUser/`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ uid }),
  })
    .then((res: any) => {
      console.log(res.status);

      if (res.status !== 200) {
        console.log("in the throw");
        throw new Error(res.status);
      }

      return res.json();
    })
    .then((res: any) => {
      message = JSON.stringify(res);

      return message;
    })
    .catch((error: Error) => {
      console.log("caught :", error.message);
      throw error;
    });

export type ServiceResponse<T> =
  | { kind: "SUCCESS"; value: T }
  | { kind: "ERROR"; errors: Ajv.ErrorObject[]; value: null }
  | { kind: "LOADING"; value: any };

export const fetchSummary = (
  uid: string
): Promise<ServiceResponse<ClientSummarySchema>> =>
  _fetch(`client/${uid}/clientSummary`)
    .then((res: { json: () => any }) => res.json())
    .then((summaryResponse: any) => {
      const errors = validateSummaryResponse.errors as Ajv.ErrorObject[];
      const isValid = validateSummaryResponse(summaryResponse);

      if (!isValid) {
        return { kind: "ERROR" as const, errors };
      }

      return { kind: "SUCCESS" as const, value: summaryResponse.clientSummary };
    })
    .catch((error: any) => ({
      kind: "ERROR" as const,
      errors: error,
      value: null,
    }));

export const xplanTransactions = (uid: string): any =>
  _fetch(`xplan/${uid}/xplanTransactions`)
    .then((res: { json: () => any }) => {
      console.log(res.json);

      return res.json();
    })
    .then((xplanTransResponse: any) => {
      console.log("xpt :", xplanTransResponse);
      const errors = validateXplanTransactions.errors as Ajv.ErrorObject[];
      const isValid = validateXplanTransactions(xplanTransResponse);

      if (!isValid) {
        return { kind: "ERROR" as const, errors };
      }

      return { kind: "SUCCESS" as const, value: xplanTransResponse };
    })
    .catch((error: any) => ({
      kind: "ERROR" as const,
      errors: error,
      value: null,
    }));

export const xplanAccess = (): any =>
  _fetch(`xplan/xplanAccess`)
    .then((res: { json: () => any }) => res.json())
    .then((xplanAccess: any) => {
      const errors = validateXplanAccess.errors as Ajv.ErrorObject[];
      const isValid = validateXplanAccess(xplanAccess);

      if (!isValid) {
        return { kind: "ERROR" as const, errors };
      }

      return { kind: "SUCCESS" as const, value: xplanAccess };
    })
    .catch((error: string) => console.log("xplan error :", error));
