import _ from "lodash";
import moment from "moment";
import { ClientSummarySchema } from "../schemas/clientSummaryResponseSchema.json";

export const getFullName1 = (client: ClientSummarySchema): string => {
  const { client1 } = client.personalInfo;

  return `${
    !_.isNil(client1?.fName)
      ? client1?.fName !== ""
        ? client1?.fName
        : "---"
      : "---"
  } ${
    !_.isNil(client1?.mName)
      ? client1?.mName !== ""
        ? client1?.mName
        : ""
      : ""
  } ${
    !_.isNil(client1?.lName)
      ? client1?.lName !== ""
        ? client1?.lName
        : "---"
      : "---"
  }`;
};

export const getFirstName1 = (client: ClientSummarySchema): string => {
  const { client1 } = client.personalInfo;

  return `${
    !_.isNil(client1?.fName)
      ? client1?.fName !== ""
        ? client1?.fName
        : "---"
      : "---"
  }`;
};

export const getMiddleName1 = (client: ClientSummarySchema): string => {
  const { client1 } = client.personalInfo;

  return `${
    !_.isNil(client1?.mName)
      ? client1?.mName !== ""
        ? client1?.mName
        : "---"
      : "---"
  }`;
};

export const getDOB1 = (client: ClientSummarySchema): string => {
  const { client1 } = client.personalInfo;

  return `${
    !_.isNil(client1?.dob)
      ? client1?.dob !== ""
        ? moment(client1?.dob).format("MMMM DD, YYYY")
        : "---"
      : "---"
  }`;
};

export const getMphone1 = (client: ClientSummarySchema): string => {
  const { client1 } = client.personalInfo;

  return `${
    !_.isNil(client1?.mPhone)
      ? client1?.mPhone !== ""
        ? client1?.mPhone
        : "---"
      : "---"
  }`;
};

export const getEmail1 = (client: ClientSummarySchema): string => {
  const { client1 } = client.personalInfo;

  return `${
    !_.isNil(client1?.email)
      ? client1?.email !== ""
        ? client1?.email
        : "---"
      : "---"
  }`;
};

export const getMarital1 = (client: ClientSummarySchema): string => {
  const { client1 } = client.personalInfo;

  return `${
    !_.isNil(client1?.marital)
      ? client1?.marital !== ""
        ? client1?.marital
        : "---"
      : "---"
  }`;
};

export const getFullName2 = (client: ClientSummarySchema): string => {
  const { client2 } = client.personalInfo;

  return `${
    !_.isNil(client2?.fName)
      ? client2?.fName !== ""
        ? client2?.fName
        : "---"
      : "---"
  } ${
    !_.isNil(client2?.mName)
      ? client2?.mName !== ""
        ? client2?.mName
        : ""
      : ""
  } ${
    !_.isNil(client2?.lName)
      ? client2?.lName !== ""
        ? client2?.lName
        : "---"
      : "---"
  }`;
};

export const getFirstName2 = (client: ClientSummarySchema): string => {
  const { client2 } = client.personalInfo;

  return `${
    !_.isNil(client2?.fName)
      ? client2?.fName !== ""
        ? client2?.fName
        : "---"
      : "---"
  }`;
};

export const getMiddleName2 = (client: ClientSummarySchema): string => {
  const { client2 } = client.personalInfo;

  return `${
    !_.isNil(client2?.mName)
      ? client2?.mName !== ""
        ? client2?.mName
        : "---"
      : "---"
  }`;
};

export const getDOB2 = (client: ClientSummarySchema): string => {
  const { client2 } = client.personalInfo;

  return `${
    !_.isNil(client2?.dob)
      ? client2?.dob !== ""
        ? moment(client2?.dob).format("MMMM DD, YYYY")
        : "---"
      : "---"
  }`;
};

export const getMphone2 = (client: ClientSummarySchema): string => {
  const { client2 } = client.personalInfo;

  return `${
    !_.isNil(client2?.mPhone)
      ? client2?.mPhone !== ""
        ? client2?.mPhone
        : "---"
      : "---"
  }`;
};

export const getEmail2 = (client: ClientSummarySchema): string => {
  const { client2 } = client.personalInfo;

  return `${
    !_.isNil(client2?.email)
      ? client2?.email !== ""
        ? client2?.email
        : "---"
      : "---"
  }`;
};

export const getMarital2 = (client: ClientSummarySchema): string => {
  const { client2 } = client.personalInfo;

  return `${
    !_.isNil(client2?.marital)
      ? client2?.marital !== ""
        ? client2?.marital
        : "---"
      : "---"
  }`;
};
